<template>
  <v-input :rules="computedRules" v-bind="fieldProps" v-on="$listeners">
    <div class="k-field-wrapper codemirror-text-area my-2" :class="this.grid">
      <codemirror
        :label="translatedLabel"
        :options="cmOption"
        v-bind="fieldProps"
        v-on="$listeners"
      />
    </div>
  </v-input>
</template>

<script>
import Field from "@/components/crud/fields/FieldMixin.vue";

export default {
  mixins: [Field],
  name: "KCodeMirror",
  data: () => ({
    cmOption: {
      value: "",
      origLeft: null,
      connect: "align",
      mode: "text/html",
      lineNumbers: true,
      collapseIdentical: false,
      highlightDifferences: true,
    },
  }),
};
</script>

<style lang="scss">
.codemirror-text-area > div {
  border: 1px solid rgba(0, 0, 0, 0.42);
}
</style>
