
import Vue from "vue";
import {
  conversionCreate,
  ConversionCreateRequest,
  ConversionCreateResponse,
} from "@/modules/conversion/api/conversionCreate";
import KForm from "@/components/KForm.vue";
import ConnectorMapperAutocomplete from "@/modules/conversion/components/ConnectorMapperAutocomplete.vue";
import KCodeMirror from "@/components/crud/fields/KCodeMirror.vue";
import { AxiosResponse } from "axios";

export default Vue.extend({
  name: "ConversionForm",
  components: { KCodeMirror, ConnectorMapperAutocomplete, KForm },
  data: () => ({
    values: {
      connectorMapperId: undefined,
      message: "",
    } as Partial<ConversionCreateRequest>,
  }),
  methods: {
    conversionCreate() {
      return conversionCreate(this.values);
    },
    handleSuccess(response: AxiosResponse<ConversionCreateResponse>) {
      console.log(response);
      console.log(response.data.data);
      console.log(response.data.data.id);
      this.$router.push({
        name: "conversion.show",
        params: { conversionId: response.data.data.id.toString() },
      });
    },
  },
});
