import { post } from "@/application/api";
import { AxiosPromise } from "axios";

export interface ConversionCreateRequest {
  connectorMapperId: number;
  message: string;
}
export interface ConversionCreateResponse {
  data: { id: number };
}

export function conversionCreate(
  conversion: Partial<ConversionCreateRequest>
): AxiosPromise<ConversionCreateResponse> {
  return post("conversion", conversion);
}
