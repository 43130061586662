
import Vue, { PropType, VueConstructor } from "vue";
import axios from "axios";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";

interface ComponentData {
  error: string;
  isLoading: boolean;
  isValid: boolean;
}

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        form: {
          validate: () => void;
          reset: () => void;
        };
      };
    }
  >
).extend({
  name: "KForm",
  components: { KFieldGroup },
  props: {
    request: {
      type: Function,
      required: true,
    },
    resetAfterSuccess: {
      type: Boolean,
      default: false,
    },
    actionColWidth: {
      type: [String, Number],
      default: "6",
    },
    handleCancel: {
      type: Function,
    },
    saveBtnProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    saveBtnText: {
      type: String,
      default: "",
    },
  },
  data: (): ComponentData => ({
    isLoading: false,
    error: "",
    isValid: true,
  }),
  methods: {
    async handleUpdate() {
      this.error = "";
      this.isLoading = true;
      try {
        const response = await this.request();
        this.$emit("request:success", response);
        if (this.resetAfterSuccess) {
          if (document?.activeElement instanceof HTMLElement) {
            document?.activeElement?.blur();
          }
          await this.$nextTick();
          this.$refs.form.reset();
        }
      } catch (e) {
        this.$emit("request:failed", e);
        if (axios.isAxiosError(e) && e.response && e.response.status === 422) {
          this.error = this.$t("global.422Error") as string;
          await this.$vuetify.goTo(0);
          this.$refs.form.validate();
          return;
        }
        this.error = this.$t("global.error") as string;
        await this.$vuetify.goTo(0);
        throw e;
      } finally {
        this.$emit("request:finished");
        this.isLoading = false;
      }
    },
  },
});
