
import Vue from "vue";
import KPaginatedAutocomplete from "@/components/crud/fields/KPaginatedAutocomplete.vue";
import { connectorMapperAutocomplete } from "@/modules/conversion/api/connectorMapperAutocomplete";

export default Vue.extend({
  name: "ConnectorMapperAutocomplete",
  components: { KPaginatedAutocomplete },
  methods: {
    connectorMapperAutocomplete,
  },
});
