import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosPromise } from "axios";

export function connectorMapperAutocomplete(
  data: PaginatedRequest
): AxiosPromise<PaginatedResponse<{ id: number; name: string }[]>> {
  return getPaginated("connector-mapper", data);
}
